import React, { useState } from 'react';
import { styled } from '../../../stitches.config';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Box from '../Box';
import useMediaQuery from "../../../helpers/useMediaQuery";

// icons 
import ArrowLeft from '../../../assets/img/mamadu/carrefour/ArrowLeft.png'
import ArrowRight from '../../../assets/img/mamadu/carrefour/ArrowRight.png'

// logo i background do wgrania 
import logo from '../../../assets/img/natemat/minisite/MS-3-logo.png';
import imgRight1 from '../../../assets/img/natemat/minisite/MS-3-img-right-1.png';
import imgRight2 from '../../../assets/img/natemat/minisite/MS-3-img-right-2.png';
import imgLeft1 from '../../../assets/img/natemat/minisite/MS-3-img-left-1.png';
import imgLeft2 from '../../../assets/img/natemat/minisite/MS-3-img-left-2.png';
import backgroundMobile from '../../../assets/img/natemat/minisite/MS-3-background-mobile.png';
import backgroundDesktop from '../../../assets/img/natemat/minisite/MS-3-background-desktop.png';
import imgMobile from '../../../assets/img/natemat/minisite/MS-3-img-mobile.png';

// strzałki do slidera

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <CustomArrow
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        >
            <img src={ArrowRight} />
        </CustomArrow>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <CustomArrow
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        >
            <img src={ArrowLeft} />
        </CustomArrow>
    );
}

// losowe ustawienie slidów

const shuffleCards = (number) => (cards) => {
    const array = Object.keys(cards).map(k => cards[k]);
    let currentIndex = array.length, randomIndex;

    while (currentIndex != 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }
    const obj = array.reduce((acc, curr, i) => (acc[`b${i + number}`] = curr, acc), {})

    return obj
}

const shuffleCardsMobile = shuffleCards(1)

export default props => {

    const isDesktop = useMediaQuery('bp4');

    const [mobileCards] = useState(() => shuffleCardsMobile([props.settings.b1, props.settings.b2, props.settings.b3, props.settings.b4]))
    const settings = {
        infinite: true,
        dots: true,
        arrows: true,
        speed: 500,
        // uwzględniać 3 lub 4 slidesToShow razem z poniższym komentarzem
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                // uwzględniać ten breakpoint gdy mają być widoczne 4 slidy na dekstopie
                breakpoint: 1370,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    arrows: true,
                    dots: true,
                }
            },
            {
                breakpoint: 996,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    centerMode: true,
                    arrows: false,
                    infinite: true,
                    dots: true,
                    centerPadding: "45px",
                }
            },
            {
                breakpoint: 720,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    arrows: false,
                    infinite: true,
                    dots: true,
                    centerPadding: "70px",
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    arrows: false,
                    infinite: true,
                    dots: true,
                    centerPadding: "50px",
                }
            },
            {
                breakpoint: 420,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    arrows: false,
                    infinite: true,
                    dots: true,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 375,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    arrows: false,
                    infinite: true,
                    dots: true,
                    centerPadding: "20px",
                }
            }
        ]
    };
    return (
        <Section>
            <Wrapper>
                <Logo>
                    <img className="logo" src={logo} />
                    <img className="left-1 desktop" src={imgRight1} />
                    <img className="left-2 desktop" src={imgRight2} />
                    <img className="right-1 desktop" src={imgLeft1} />
                    <img className="right-2 desktop" src={imgLeft2} />
                    <img className="background-img mobile" src={imgMobile} />
                </Logo>

                {/* <CarouselWrapper>
                        <Slider {...settings}>
                            <Box {...props}
                                id="b1"
                                type="AMiniSite"
                                settings={props.settings.b1}
                            />
                            <Box {...props}
                                id="b2"
                                type="AMiniSite"
                                settings={props.settings.b2}
                            />
                            <Box {...props}
                                id="b3"
                                type="AMiniSite"
                                settings={props.settings.b3}
                            />
                            <Box {...props}
                                id="b4"
                                type="AMiniSite"
                                settings={props.settings.b4}
                            />
                        </Slider>
                    </CarouselWrapper> 
                    */}

                <BoxWrapper>
                    <Box {...props}
                        id="b1"
                        type="AMiniSite"
                        settings={props.settings.b1}
                    />
                    <Box {...props}
                        id="b2"
                        type="AMiniSite"
                        settings={props.settings.b2}
                    />
                    <Box {...props}
                        id="b3"
                        type="AMiniSite"
                        settings={props.settings.b3}
                    />
                </BoxWrapper>

            </Wrapper>
        </Section>
    );
}

// slider

const CustomArrow = styled("div", {
    "img": {
        width: "43px",
        height: "43px",
        margin: "auto",
        position: "absolute",
        top: "-10px",
        left: "0"
    }
});

const CarouselWrapper = styled("div", {
    marginBottom: "55px",
    overflow: "visible",
    ".slick-list": {
        marginBottom: "50px",
        "@bp4": {
            marginBottom: "0px",
            padding: "10px 0px"
        }
    },
    ".slick-slide": {
        "& > div": {
            display: "grid",
            placeItems: "center"
        }
    },
    ".slick-arrow": {
        zIndex: 10
    },
    ".slick-prev": {
        left: "380px",
        top: "382px",
        "@bp5": {
            left: "530px",
        }
    },
    ".slick-next": {
        right: "410px",
        top: "382px",
        "@bp5": {
            right: "560px",
        }
    },
    ".slick-dots": {
        bottom: "-35px",
        "li": {
            margin: "0",
            width: "18px",
            height: "9px",
            "&.slick-active": {
                button: {
                    "&:before": {
                        /* main kolor minisite */
                        color: "#02c3a5",
                        opacity: 1,
                        "@bp4": {
                            borderRadius: "10px"
                        }
                    }
                }
            },
            button: {
                width: "9px",
                height: "9px",
                padding: "0",
                "&:before": {
                    width: "9px",
                    height: "9px",
                    fontSize: "9px",
                    lineHeight: "9px",
                    color: "white",
                    opacity: 1,
                    "@bp4": {
                        borderWidth: "0px",
                        borderRadius: "10px"
                    }
                }
            }
        }
    }
});

const Section = styled("div", {
    overflow: "hidden",
    width: "100%",
    paddingBottom: "1px",
    backgroundColor: "#0094a5",
    backgroundImage: `url("${backgroundMobile}")`,
    backgroundPosition: "top center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    "@bp4": {
        backgroundImage: `url("${backgroundDesktop}")`,
        height: "575px",
    },
    "@bp5": {
        height: "660px"
    },
    ".desktop": {
        display: "none",
        "@bp4": {
            display: "block"
        }
    },
    ".mobile": {
        "@bp4": {
            display: "none"
        }
    },
    "& .minisite-box": {
        minHeight: "320px",
        height: "100%",
        paddingBottom: "10px",
        "& img": {
            "@bp5": {
                width: "394px",
                height: "267px",
            }
        },
        "@bp5": {
            width: "400px",
            minHeight: "389px",
            height: "auto",
            paddingBottom: "10px",
        }
    }
});

const Wrapper = styled("div", {
    width: "100%",
    margin: "0 auto",
    paddingTop: "585px",
    paddingBottom: "40px",
    "@bp4": {
        paddingTop: "210px",
        paddingBottom: "0px",
        flexDirection: "row",
        maxWidth: "972px",
        justifyContent: "space-between",
        alignItems: "flex-start"
    },
    "@bp5": {
        paddingTop: "230px",
        maxWidth: "1280px"
    },
    /* ustawiać ten breakpoint gdy są 4 slidy na dekstopie 
    @media only screen and (min-width: 1370px) {
        max-width: 1266px;
    } */
    "h1": {
        fontSize: "30px",
        lineHeight: "38px",
        color: "#D7141A",
        textAlign: "center",
        paddingTop: "10px",
        margin: "0",
        fontWeight: 800,
        fontFamily: "$grotesk",
        position: "relative",
        "@bp4": {
            marginBottom: "0px",
            fontSize: "34px",
            lineHeight: "42px"
        }
    },
    ".h1-bold": {
        color: "#242424",
        marginBottom: "0px",
        paddingTop: "10px",
        "@bp4": {
            paddingTop: "0",
            marginBottom: "185px"
        }
    }
});

const Logo = styled("div", {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    position: "absolute",
    top: "0px",
    left: "0px",
    zIndex: 5,
    ".logo": {
        height: "225px",
        marginTop: "40px",
        "@bp4": {
            height: "200px",
            marginTop: "10px",
        },
        "@bp5": {
            height: "220px",
            marginTop: "15px",
        },
    },

    ".right-2": {
        position: "absolute",
        width: "350px",
        top: "8px",
        left: "calc(50% - 500px)",
        "@bp5": {
            width: "400px",
            left: "calc(50% - 620px)",
        }
    },
    ".right-1": {
        position: "absolute",
        top: 0,
        "@bp4": {
            height: "675px",
            left: "calc(50% - 950px)",
        },
        "@bp5": {
            left: "calc(50% - 1100px)",
            height: "775px",
        },
        "@media (min-width: 1920px)": {
            left: "-175px",
        },
    },
    ".left-2": {
        position: "absolute",
        width: "350px",
        top: "8px",
        right: "calc(50% - 500px)",
        "@bp5": {
            width: "400px",
            right: "calc(50% - 620px)",
        }
    },
    ".left-1": {
        position: "absolute",
        top: 0,
        "@bp4": {
            height: "875px",
            right: "calc(50% - 1050px)",
            top: "-200px",
        },
        "@bp5": {
            right: "calc(50% - 1200px)",
            top: "-220px",
            height: "960px",
        },
        "@media (min-width: 1920px)": {
            right: "-215px",
        },
    },
    ".mobile": {
        width: "600px",
        marginTop: "-95px",
    },
});


const BoxWrapper = styled("div", {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    gap: "24px",
    position: "relative",
    zIndex: 10,
    "@bp4": {
        flexDirection: "row",
        alignItems: "stretch"
    }
});
